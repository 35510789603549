import React from 'react';

import HighlightButton from '../HighlightButton/HighlightButton';

export default function Commissions() {
	const [showForm, setShowForm] = React.useState(false);

	return (
		<div className="mainContainer">
			<div className="bodyContainer">
				<h1>COMMISSIONS</h1>

				<p>Are you looking for high-quality arrangements for your group? Do you need parts transcribed for an upcoming gig? I'm here to help. <b>I will arrange a song or piece of your choice</b>, tailored to the level of your performers, within several days to a few weeks.</p>

				<p>Here's what you can expect when I complete your commission.</p>
				<ul>
						<li>A .pdf file of the full completed score</li>
						<li>A .mp3 file of the digital/MIDI playback</li>
						<li>Expertly notated guidelines for phrasing, breaths, articulations, and dynamics</li>
				</ul>

				<div style={{ transition: 'opacity 0.5s ease-in-out', opacity: showForm ? 0 : 1, pointerEvents : showForm ? 'none' : 'auto' }}>
					<HighlightButton onClick={() => setShowForm(!showForm)}>Get Started</HighlightButton>
				</div>

				<section style={{ transition: 'opacity 0.5s ease-in-out', opacity: showForm ? 1 : 0, pointerEvents : showForm ? 'auto' : 'none'}}>
					<p>Are you looking to commission an arrangement or a transcription? (This will open a new window)</p>
					<span style={{ "margin-right": "10px" }}><HighlightButton onClick={() => window.open("https://forms.gle/nTqd2NjaVAhxJnkr7", "_blank", "noopener,noreferrer")}>Arrangement</HighlightButton></span>
					<span><HighlightButton onClick={() => window.open("https://forms.gle/dYoKkPvBnWAqnvb98", "_blank", "noopener,noreferrer")}>Transcription</HighlightButton></span>
				</section>


				{/* <p>If you're not sure about commissioning a request due to the length of the piece or number of instruments, please send me a message first and we can work out a quote. I'm excited to work with you to create something great!</p> */}
			</div>
		</div>
	);
}