import React from 'react';
import '../../css/Music.css';

class Music extends React.Component {
  render() {
    return (
      <div className="mainContainer">
        <div className="bodyContainer">
          <p>
            <b>Lawrence Luo</b> is a composer, arranger, and multi-instrumentalist based in Boston & San Francisco with almost two decades of experience composing, arranging, and performing music.
            He has garnered awards for his contemporary compositions and arrangements, and his work for chamber ensembles, jazz-fusion bands, and a cappella groups are performed in-concert regularly.
          </p>

          <p>Recent projects:</p>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=Jc86LBy_gws&list=PLZ8xVCciQ1ohq810z6AmckrDMgbjwDR2i&index=1&ab_channel=Lawrence%2F%2FTonic">Ballads & Daydreams - Jazz Duo</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/artist/0HVbw8ntLBVDL9fpJYMljZ?si=FtekFl36RtuUTf78rNBbBA">Alex Togashii</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/QuarterAfterBand/">Quarter After</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUmsLPXkFTBv5TpdUuedSdw">The A Cappella Collective</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/buinachord2">BU In Achord</a></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Music;


// Not used: For embedding youtube videos
// import ReactPlayer from 'react-player';
// <ReactPlayer
//   className="videoPlayer"
//   url='https://www.youtube.com/watch?v=3W09RlqTy8I'
//   volume={1}
//   playing={false}
//   controls={true}
//   light={true}
// />
