import React from 'react';

import { SpeedInsights } from '@vercel/speed-insights/react';

import './css/Reset.css';
import './css/App.css';

import Main from './Components/MainNavigator';
import Footer from './Components/footer';
import Header from './Components/header';

const App = () => {
  return (
    <div className="backgroundContainer">
      <div className="appContainer">
        <Header />
        <Main/>
        <Footer/>
      </div>

      <SpeedInsights />
    </div>
  )
}

export default App;
