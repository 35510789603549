import React from 'react';

import '../css/Header.css';

const TechHeader = () => {
  return (
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer"><a href="/">HOME</a></span>
        <span id="rightContainer">
          <a className="navLink" href="/teaching">TEACHING</a>
        </span>
      </div>
    </div>
  )
}

const MusicHeader = () => {
  return (
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer"><a className="navLink" href="/">HOME</a></span>
        <span id="rightContainer">
          <a className="navLink" href="/commissions">COMMISSIONS</a>
          <a className="navLink" href="/arrangements">ARRANGEMENTS</a>
        </span>
      </div>
    </div>
  )
}

const Header = () => {
  return process.env.REACT_APP_WEBSITE_TYPE === "MUSIC" ? <MusicHeader /> : <TechHeader />
}

export default Header;