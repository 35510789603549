import React from 'react';

import '../../css/Home.css';

class Home extends React.Component {
  render() {
    return (
      <div className="mainContainer">
        <div className="bodyContainer">
          <div className="homeContainer">
            <div className="profileImageContainer">
              <img shadow={5} className="profileImage" alt="Lawrence Luo portrait" src={"/static/img/law_cropped.png"}></img>
            </div>

            <div>
              <div id="superscript"><em>greetings from</em></div>
              <h1>LAWRENCE LUO</h1>
              <p className="description">I am a creator. I build web and mobile apps. As I learn, I empower others by sharing my knowledge. Let&#39;s create something!</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home;
