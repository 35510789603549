import React from 'react';

const Page404 = () => {
  return (
    <div className="mainContainer">
      <div className="bodyContainer">
        <div>
          <h1>404 Error</h1>
          <p>No page for {window.location.pathname} exists...</p>
        </div>
      </div>
    </div>
  )
}

export default Page404;
